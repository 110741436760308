<template>
  <div class="subitem-container" :style="{'width': width,'height': height+'px'}">
    <div class="title">
      <div class="corner-left"></div>
      <div class="txt">{{title}}</div>
      <div class="corner-right"></div>
    </div>
    <div class="count" :style="{'color': color}">{{count}}</div>
    <div class="bottom">
      <div class="corner-left"></div>
      <div class="txt"></div>
      <div class="corner-right"></div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'Subitem',
    props:{
      width:{
        type: String,
        default: '100%'
      },
      height:{
        type: String,
        default: 'auto'
      },
      title:{
        type: String,
        default: '标题'
      },
      count:{
        type: Number,
        default: 0
      },
      color:{
        type: String,
        default: ''
      }
    }
  }
</script>
<style lang="stylus" scoped>
.subitem-container
  background rgba(10, 19, 41, 0.2)
  box-shadow 0px 1px 18px 1px #0050B3 inset
  border 1px solid #01ABFA
  margin-bottom 12px
  margin-left 10px
  margin-right 10px
  box-sizing border-box
  display flex
  justify-content space-between
  flex-direction column
  .bottom
    display flex
    height 7.33px
    .corner-left
      width 6.33px
      height 6.33px
      border 1px solid #69D7FE
      border-right 0
      border-top 0
    .corner-right
      width 6.33px
      height 6.33px
      border 1px solid #69D7FE
      border-left 0
      border-top 0
    .txt
      flex 1
      margin-top 11px
      text-align center
  .title
    font-size 14px
    color #fff
    line-height 21px
    // padding-left 30px
    // margin-top 11px
    text-align center
    display flex
    .corner-left
      width 6.33px
      height 6.33px
      border 1px solid #69D7FE
      border-right 0
      border-bottom 0
    .corner-right
      width 6.33px
      height 6.33px
      border 1px solid #69D7FE
      border-left 0
      border-bottom 0
    .txt
      flex 1
      margin-top 11px
      text-align center
  .count
    color #02E3E5
    font-size 28px
    font-weight bold
    line-height 37px
    text-align center
    margin-top 11px
    box-sizing border-box
</style>