<template>
  <div :class="isHome ? 'home_attendance_list_container':'attendance_list_container'">
    <div class="attendance_list_content">
      <el-table
        :data="list"
        class="custom-table-2 hidden-tbody"
        :border="isHome ? true : false">
        <el-table-column label="排名" type="index" align="center" width="50"></el-table-column>
        <el-table-column label="项目名称" prop="projectName" align="center"></el-table-column>
        <el-table-column label="在场人数" prop="onSiteWorkerNum" align="center"></el-table-column>
        <el-table-column label="今日出勤" prop="todayAttendanceWorkerNum" align="center"></el-table-column>
        <el-table-column label="出勤率" prop="rate" align="center"></el-table-column>
      </el-table>
      <vueSeamlessScroll :data="list" :class-option="classOption" class="warp">
        <el-table
          :data="list"
          :show-header="false"
          :highlight-current-row="false"
          @row-click="handleRowClick"
          class="custom-table-2 hidden-thead"
          stripe>
          <el-table-column label="排名" type="index" align="center"></el-table-column>
          <el-table-column label="项目名称" prop="projectName" align="center"></el-table-column>
          <el-table-column label="在场人数" prop="onSiteWorkerNum" align="center"></el-table-column>
          <el-table-column label="今日出勤" prop="todayAttendanceWorkerNum" align="center"></el-table-column>
          <el-table-column label="出勤率" prop="rate" align="center"></el-table-column>
        </el-table>
      </vueSeamlessScroll>
    </div>
  </div>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "Attendance-list",
    components: {vueSeamlessScroll},
    props: {
      list: {
        type: Array,
        default:()=>[]
      },
      isHome:{ // 判断是否首页
        type: Boolean,
        default:false
      }
    },
    data(){
      return {
        classOption: {
          // singleHeight: 30
        },
      }
    },
    methods: {
      handleRowClick(row, column, event){
        console.log(row.memberNo)
        this.$router.push({
          path: '/attendance',
          query: {
            memberNo: row.memberNo
          }
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
.attendance_list_container,.home_attendance_list_container
  .seamless-warp 
    height 229px
    overflow hidden

  .hidden-tbody.el-table
    height 34px
    box-sizing border-box
    tbody //隐藏上面表格的tbody
      display none
      overflow hidden
  .warp
    height 200px
    width 100%
    margin 0 auto
    overflow hidden
  ul.item
    list-style none
    padding 0
    margin 0 auto
    li,a
      display block
      height 30px
      line-height 30px
      display flex
      justify-content space-between
      font-size 15px
.home_attendance_list_container
    .hidden-tbody.el-table
      height 48px
</style>

<style lang="stylus">
  .home_attendance_list_container
    .el-table td, .el-table th.is-leaf
        border 1!important
  .attendance_list_container
    .el-form-item__label
      color #9197A2 !important
    .el-form-item__content
      color #9197A2 !important
    .el-table
        color #fff!important
    .el-table th
        background-color transparent!important
    .el-table td, .el-table th
        padding 9px 0 8px 0
    .el-table th, .el-table tr
        background-color transparent!important
    .el-table, .el-table__expanded-cell
        background-color transparent!important
    .el-table thead
        color #40A9FF!important
    .el-table td, .el-table th.is-leaf
        border 0!important
    .el-table td
        background rgba(0, 80, 179, .2)!important
    .el-table--striped .el-table__body tr.el-table__row--striped td
        background-color transparent!important
    .el-table--border::after, .el-table--group::after, .el-table::before
        background-color transparent!important
</style>
