<template>
  <div :class="type=='normal' ? 'item-middle-container' : 'item-middle-container-large'">
    <img src="../../../../assets/kanban/icon-worker.png" v-show="showIcon" />
    <span class="title">{{title}}</span>
    <span class="num" :class="numColor">{{num}}</span>
  </div>
</template>
<script>
  export default {
    name: 'Item-middle-container',
    props:{
      title:{
        type: String,
        default: '标题'
      },
      num:{
        type: String,
        default: '0'
      },
      icon:{
        type: String,
        default: ''
      },
      showIcon:{
        type: Boolean,
        default: false
      },
      titleStyle:{
        type: String,
        default: ''
      },
      numStyle:{
        type: String,
        default: ''
      },
      type:{
        type: String,
        default: 'normal'
      },
      numColor:{
        type: String,
        default: 'blue'
      }
    },
    data(){
      return {
        
      }
    }
  }
</script>
<style lang="stylus" scoped>
.newcolor
  color #f00
.green
  color #36CFC9
.yellow
  color #FAAD14
.pink
  color #FF85C0
.blue
  color #69D7FE
.item-middle-container
  background rgba(10, 64, 181, 0.18)
  border 2px solid #064093
  margin 0 10px 21px
  position relative
  display flex
  padding 12px 22px
  box-sizing border-box
  line-height 62px
  justify-content space-between
  img
    width 47px
    height 48px
  .title
    font-size 16px
    color #FFFFFF
    line-height 31px
    width 89px
  .num
    font-size 24px
    line-height 31px
.item-middle-container-large
  background rgba(10, 64, 181, 0.18)
  border 2px solid #064093
  margin 21px 10px
  position relative
  display flex
  padding 12px 22px
  box-sizing border-box
  line-height 72px
  justify-content space-between
  img
    width 47px
    height 48px
  .title
    font-size 16px
    color #03CDFF
    line-height 48px
    width 89px
    margin-right 56px
  .num
    // color #69D7FE
    font-size 36px
    line-height 48px
</style>