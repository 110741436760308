<template>
  <el-dialog
    :visible.sync="visible"
    width="40%"
    :before-close="handleClose"
    custom-class="company-list-dialog">
    <div v-if="false" slot="title"></div>
    <div class="btn-close" @click="handleClose">
      <i class="el-icon-close" />
    </div>
    <item-container width="100%" height="auto" title="地图" hiddenHeaderFooter>
      <el-table :data="tableData" stripe>
        <el-table-column prop="companyName" label="公司名称" align="center"></el-table-column>
        <el-table-column prop="projectNum" label="项目数量" align="center"></el-table-column>
        <el-table-column prop="rosterPeopleNum" label="项目人数" align="center"></el-table-column>
      </el-table>
    </item-container>
  </el-dialog>
</template>
<script>
import ItemContainer from '../components/Item-container.vue'
export default {
  name: "Company-list-dialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {ItemContainer},
  data(){
    return {
      tableData: [],
      visible: this.showDialog,
      form:{},
      rules:{},
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
    }
  },
  methods: {
    getCurrentRow(index){
      this.radio = index
      console.log(this.radio)
    },
    /**
     * 每页显示条目个数切换
     * @param val
     */
    getCurrentPageSize(val){
        this.pagination.currentPage = 1
        this.pagination.pageSize = val
        this.getCurrentPage()
      },
    /**
     * 页码切换，触发重载数据
     * @param val
     */
    getCurrentPage(val) {
      this.pagination.currentPage = val
      this.getDataList()
    },
    getDataList(){
      this.tableData = [{
        companyId:'001',
        companyName:'上海建工一建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'002',
        companyName:'上海建工二建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'003',
        companyName:'上海建工三建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'004',
        companyName:'上海建工四建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'005',
        companyName:'上海建工五建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'006',
        companyName:'上海建工六建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'007',
        companyName:'上海建工七建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'008',
        companyName:'上海建工八建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'009',
        companyName:'上海建工九建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'010',
        companyName:'上海建工十建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'011',
        companyName:'上海建工十一建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'012',
        companyName:'上海建工十二建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },{
        companyId:'013',
        companyName:'上海建工十三建集团有限公司',
        projectNum:20,
        rosterPeopleNum:200
      },]
    },
    handleSearch(){
      console.log('查询~')
      this.pagination.currentPage = 1
      this.getDataList()
    },
    handleClose(){
      this.visible = false
      this.$emit('close')
    }
  },
  watch: {
    showDialog() {
      this.visible = this.showDialog
    },
    visible(val, oldVal) {
      if (!val) {
        this.handleClose()
      }else{
        this.getDataList()
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.company-list-dialog
  .btn-close
    color #01abfa
    position absolute
    right 3px
    top 5px
    width 20px
    height 20px
    z-index 999
    text-align center
    border solid 1px #01abfa
    cursor pointer
</style>
<style lang="stylus">
.company-list-dialog
  &.el-dialog
    .el-dialog__header
      display none
    
    .dj-dialog-content
      padding 0
      overflow unset
    
    .el-dialog__body
      padding 0
      .el-table
          color #fff!important
          font-size 16px
        .el-table th
            background-color #0A1329!important
        .el-table td, .el-table th
            padding 9px 0 8px 0
        .el-table th, .el-table tr
            background-color #0A1329!important
        .el-table, .el-table__expanded-cell
            background-color transparent!important
        .el-table thead
            color #40A9FF!important
        .el-table td, .el-table th.is-leaf
            border 0!important
        .el-table td
            background rgba(0, 80, 179, .2)!important
        .el-table--striped .el-table__body tr.el-table__row--striped td
            background-color transparent!important
        .el-table--border::after, .el-table--group::after, .el-table::before
            background-color transparent!important
    

</style>