<template>
  <div class="subitem-card-container">
    <subitem
      :title="item.title"
      :count="item.count"
      v-for="(item,index) in list"
      :key="index"
      height="104"
      :color="colorList[index]">
    </subitem>
  </div>
</template>
<script>
import Subitem from './Subitem.vue'
  export default {
    name: 'Subitem-card',
    components: {
      Subitem
    },
    props:{
      list:{
        type: Array,
        default: () => []
      }
    },
    data(){
      return {
        colorList:["#02E3E5", "#FEB300", "#1CC98F"]
      }
    }
  }
</script>
<style lang="stylus" scoped>
.subitem-card-container
  display flex
  margin-top 38px
  .title
    font-size 14px
    color #fff
    line-height 21px
    // padding-left 30px
    // margin-top 11px
    text-align center
    display flex
    .corner-left
      width 31px
      height 32px
      border 2px solid #69D7FE
      border-right 0
      border-bottom 0
    .corner-right
      width 31px
      height 32px
      border 2px solid #69D7FE
      border-left 0
      border-bottom 0
    .txt
      flex 1
      margin-top 11px
  .count
    color #02E3E5
    font-size 28px
    font-weight bold
    line-height 37px
</style>