<template>
  <div class="kanban-header-container">
    <span class="back-btn" @click="handleBack"><img src="../../../../assets/kanban/icon-back.png" width="24" /> 返回</span>    
    <span>劳务实名制企业可视化看板</span>
    <span class="time">{{currentTime | dateTimeFormat}}</span>
  </div>
</template>
<script>
  export default {
    name: 'Kanban-header',
    data() {
      return {
        currentTime: '',
        timer: null
      }
    },
    methods:{
      handleBack(){
        this.$router.go(-1)
      }
    },
    mounted() {
      let _this = this
      this.timer = setInterval(() => {
        _this.currentTime = new Date()
      }, 1000)
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    }
  }
</script>
<style lang="stylus" scoped>
.kanban-header-container
  color #69D7FE
  text-align center
  font-size 40px
  line-height 61px
  background url('../../../../assets/kanban/title-bg.png') center center
  margin-top 22px
  margin-bottom 20px
  display flex
  justify-content space-between
  .back-btn
    width 219px
    font-size 16px
    color #03CDFF
    margin-left 38px
    line-height 61px
    text-align left
    cursor pointer
  .time
    width 219px
    font-size 18px
    color #fff
    margin-right 40px
    line-height 61px
    display inline-block
</style>