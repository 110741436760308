<template>
  <div class="item-container" :style="{'width': width,'height': height+'px'}">
    <div class="title" v-show="!hiddenHeaderFooter">
      <div class="corner-left"></div>
      <div class="txt">{{title}}</div>
      <div class="corner-right"></div>
    </div>
    <div style="position:relative;">
      <slot  />
    </div>
    <div class="bottom" v-show="!hiddenHeaderFooter">
      <div class="corner-left"></div>
      <div class="txt"></div>
      <div class="corner-right"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Item-container',
    props:{
      width:{
        type: String,
        default: '100%'
      },
      height:{
        type: String,
        default: '220px'
      },
      title:{
        type: String,
        default: '标题'
      },
      hiddenHeaderFooter:{
        type: Boolean,
        default: false
      },
    }
  }
</script>
<style lang="stylus" scoped>
.item-container
  background rgba(10, 19, 41, 0.2)
  box-shadow 0px 1px 18px 1px #0050B3 inset
  border 1px solid #01ABFA
  margin-bottom 19px
  box-sizing border-box
  position relative
  display flex
  flex-direction column
  justify-content space-between
  .bottom
    display flex
    .corner-left
      width 31px
      height 32px
      background url('../../../../assets/kanban/corner-left-bottom.png')
    .corner-right
      width 31px
      height 32px
      background url('../../../../assets/kanban/corner-right-bottom.png')
    .txt
      flex 1
      margin-top 11px
  .title
    font-size 16px
    color #03CDFF
    line-height 21px
    // padding-left 30px
    // margin-top 11px
    display flex
    .corner-left
      width 31px
      height 32px
      background url('../../../../assets/kanban/corner-left-top.png')
    .corner-right
      width 31px
      height 32px
      background url('../../../../assets/kanban/corner-right-top.png')
    .txt
      flex 1
      margin-top 11px

</style>