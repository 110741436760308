<template>
  <div class="kanban-container">
    <kanban-header />
    <div class="body-row">
        <div class="left-colum">
            <item-container width="100%" height="222" title="项目统计">
                <subitem-card :list="projectCountList" />
            </item-container>
            <item-container width="100%" height="220" title="管理人员统计">
                <el-table :data="managerList" stripe>
                    <el-table-column label="名称" prop="managerTypeName" align="center"></el-table-column>
                    <el-table-column label="在册" prop="rosterWorkerNum" align="center"></el-table-column>
                    <el-table-column label="在场" prop="onSiteWorkerNum" align="center"></el-table-column>
                </el-table>
            </item-container>
            <item-container width="100%" height="220" title="作业人员统计">
                <el-table :data="workerList" stripe>
                    <el-table-column label="名称" prop="subcontractTypeName" align="center"></el-table-column>
                    <el-table-column label="在册" prop="rosterWorkerNum" align="center"></el-table-column>
                    <el-table-column label="在场" prop="onSiteWorkerNum" align="center"></el-table-column>
                </el-table>
            </item-container>
            <item-container width="100%" height="220" title="其他人员统计">
                <el-table :data="otherWorkerList" stripe>
                    <el-table-column label="名称" prop="otherTypeName" align="center"></el-table-column>
                    <el-table-column label="在册" prop="rosterWorkerNum" align="center"></el-table-column>
                    <el-table-column label="在场" prop="onSiteWorkerNum" align="center"></el-table-column>
                </el-table>
            </item-container> 
        </div>
        <div class="main">
            <item-container width="100%" height="auto" title="地图" hiddenHeaderFooter>
                <baidu-map
                    class="map"
                    :center="center"
                    :zoom="zoom"
                    :scroll-wheel-zoom="true"
                    :continuous-zoom="true"
                    @ready="handler">
                    <bm-marker :position="item" v-for="(item,index) in newPoints" :key="index" />
                    <bm-map-type :map-types="['BMAP_NORMAL_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
                </baidu-map>
                <img src="../../../assets/kanban/icon-view-company.png" class="btn-view-company" @click="showCompanyList" />
            </item-container>
            <item-container width="100%" height="321" title="项目实时考勤统计">
                <!-- <div style="position:absolute; top: 0;width:100%;"> -->
                <!-- <el-table :data="attendanceList" stripe>
                    <el-table-column label="排名" type="index" align="center"></el-table-column>
                    <el-table-column label="项目名称" prop="projectName" align="center"></el-table-column>
                    <el-table-column label="在场人数" prop="onSiteWorkerNum" align="center"></el-table-column>
                    <el-table-column label="今日出勤" prop="todayAttendanceWorkerNum" align="center"></el-table-column>
                    <el-table-column label="出勤率" prop="rate" align="center"></el-table-column>
                </el-table> -->
                <attendance-list :list="attendanceList" />
                <!-- </div> -->
            </item-container>
        </div>
        <div class="right-colum">
            <item-container width="100%" height="462" title="项目预警">
                <ul class="warn">
                    <li>
                        <img src="../../../assets/kanban/icon-overtime.png" width="78" height="78" />
                        <div class="info">
                            <span class="title">超时预警</span><br />
                            <span class="num">6</span>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/kanban/icon-absence.png" width="78" height="78" />
                        <div class="info">
                            <span class="title">缺勤预警</span><br />
                            <span class="num">11</span>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/kanban/icon-certification.png" width="78" height="78" />
                        <div class="info">
                            <span class="title">证书预警</span><br />
                            <span class="num">4</span>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/kanban/icon-morningmeeting.png" width="78" height="78" />
                        <div class="info">
                            <span class="title">每日晨会预警</span><br />
                            <span class="num">13</span>
                        </div>
                    </li>
                    <li style="margin-bottom:0">
                        <img src="../../../assets/kanban/icon-overage.png" width="78" height="78" />
                        <div class="info">
                            <span class="title">超龄预警</span><br />
                            <span class="num">13</span>
                        </div>
                    </li>
                    <li style="margin-bottom:0">
                        <img src="../../../assets/kanban/icon-dangerWorkType-red.png" width="78" height="78" />
                        <div class="info">
                            <span class="title">危险工种预警</span><br />
                            <span class="num hightlight">13</span>
                        </div>
                    </li>
                </ul>
            </item-container>
            <item-container width="100%" height="464" title="工人实名制分析">
                <div style="display:flex; justify-content: space-between">
                    <div style="flex:1">
                        <item-middle-container
                            title='工人总登记'
                            num=1200
                            showIcon
                            type="large" />
                    </div>
                </div>
                <div style="display:flex">
                    <div style="flex:1"><item-middle-container title='总退场' num=1200 numColor="yellow" /></div>
                    <div style="flex:1"><item-middle-container title='平均年龄' num=28 numColor="blue" /></div>
                </div>
                <div style="display:flex">
                    <div style="flex:1"><item-middle-container title='男性' num=600 numColor="green" /></div>
                    <div style="flex:1"><item-middle-container title='女性' num=600 numColor="pink" /></div>
                </div>
                <div class="title-line">
                    <div class="name">劳务来源</div>
                    <div class="content">
                        1.江西 2.安徽省 3.上海市 <img src="../../../assets/kanban/icon-more.png" width="16" />
                    </div>
                </div>
                <div class="title-line">
                    <div class="name">工种</div>
                    <div class="content">
                        1.架子工 2.泥工 3.水电工 
                        <img src="../../../assets/kanban/icon-more.png" width="16" />
                    </div>
                </div>
            </item-container>
        </div>
    </div>
    <company-list-dialog
      :showDialog="isShowCompanyList"
      @close="hiddenDialog" />
  </div>
</template>

<script>
// @ is an alias to /src
import KanbanHeader from './components/Kanban-header.vue'
import ItemContainer from './components/Item-container.vue'
import SubitemCard from './components/Subitem-card.vue'
import ItemMiddleContainer from './components/Item-middle-container'
import CompanyListDialog from './components/Company-list-dialog'
import AttendanceList from './components/Attendance-list'
export default {
  name: 'Kanban',
  components: {
    KanbanHeader,
    ItemContainer,
    SubitemCard,
    ItemMiddleContainer,
    CompanyListDialog,
    AttendanceList
  },
  data(){
    return {
      isShowCompanyList: false,
      center: {lng: 0, lat: 0},
      zoom: 3,
      newPoint:{
        lng: 0,
        lat: 0
      },
      newPoints:[],
      projectCountList: [{
          title:'在建项目',
          count:300
      },{
          title:'停工项目',
          count:300
      },{
          title:'完成项目',
          count:300
      },],
      managerList:[{
          onSiteWorkerNum: 1000,
          rosterWorkerNum: 1200,
          managerTypeName: "总包管理人员"
      },{
          onSiteWorkerNum: 1000,
          rosterWorkerNum: 1200,
          managerTypeName: "分包管理人员"
      },{
          onSiteWorkerNum: 1000,
          rosterWorkerNum: 1200,
          managerTypeName: "其他管理人员"
      }],
      workerList:[{
          onSiteWorkerNum: 1000,
          rosterWorkerNum: 1200,
          subcontractTypeName: "一级劳务分包"
      },{
          onSiteWorkerNum: 1000,
          rosterWorkerNum: 1200,
          subcontractTypeName: "一级专业分包"
      },{
          onSiteWorkerNum: 1000,
          rosterWorkerNum: 1200,
          subcontractTypeName: "二级劳务分包"
      }],
      otherWorkerList:[{
          onSiteWorkerNum: 1000,
          rosterWorkerNum: 1200,
          otherTypeName: "一级劳务分包"
      },{
          onSiteWorkerNum: 1000,
          rosterWorkerNum: 1200,
          otherTypeName: "一级专业分包"
      }],
      attendanceList:[{
          projectId: '001',
          projectName: '项目1',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },{
          projectId: '002',
          projectName: '项目2',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },{
          projectId: '003',
          projectName: '项目3',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },{
          projectId: '004',
          projectName: '项目4',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },{
          projectId: '005',
          projectName: '项目5',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },
      {
          projectId: '006',
          projectName: '项目6',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },
      {
          projectId: '007',
          projectName: '项目7',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },{
          projectId: '008',
          projectName: '项目8',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },{
          projectId: '009',
          projectName: '项目9',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      },{
          projectId: '010',
          projectName: '项目10',
          onSiteWorkerNum: 400,
          rosterWorkerNum: 425,
          todayAttendanceWorkerNum: 366,
          rate: '91.5%',
      }
      ],
    }
  },
  methods: {
    hiddenDialog(){
        this.isShowCompanyList=false
    },
    showCompanyList(){
        this.isShowCompanyList=true
    },
    handler ({BMap, map}) {
      console.log(BMap, map)
      let that = this;
      that.map = map;
      map.setMapStyleV2(
        {
          // styleId:'73979e40c609f11f44227183a85a0f42'
          styleJson:[{
    "featureType": "land",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "color": "#091220ff"
    }
}, {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "color": "#113549ff"
    }
}, {
    "featureType": "green",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "color": "#0e1b30ff"
    }
}, {
    "featureType": "building",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "building",
    "elementType": "geometry.topfill",
    "stylers": {
        "color": "#113549ff"
    }
}, {
    "featureType": "building",
    "elementType": "geometry.sidefill",
    "stylers": {
        "color": "#143e56ff"
    }
}, {
    "featureType": "building",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#dadada00"
    }
}, {
    "featureType": "subwaystation",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "color": "#113549B2"
    }
}, {
    "featureType": "education",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "color": "#12223dff"
    }
}, {
    "featureType": "medical",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "color": "#12223dff"
    }
}, {
    "featureType": "scenicspots",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "color": "#12223dff"
    }
}, {
    "featureType": "highway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "weight": "4"
    }
}, {
    "featureType": "highway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "highway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#fed66900"
    }
}, {
    "featureType": "highway",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "highway",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "highway",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "highway",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "arterial",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "weight": "2"
    }
}, {
    "featureType": "arterial",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "arterial",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffeebb00"
    }
}, {
    "featureType": "arterial",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "arterial",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "arterial",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "local",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on",
        "weight": "1"
    }
}, {
    "featureType": "local",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "local",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "local",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "local",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#979c9aff"
    }
}, {
    "featureType": "local",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffffff"
    }
}, {
    "featureType": "railway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "subway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "weight": "1"
    }
}, {
    "featureType": "subway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#d8d8d8ff"
    }
}, {
    "featureType": "subway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "subway",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "subway",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#979c9aff"
    }
}, {
    "featureType": "subway",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffffff"
    }
}, {
    "featureType": "continent",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "continent",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "continent",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "continent",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "city",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "city",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "city",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "city",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "town",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "town",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "town",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#454d50ff"
    }
}, {
    "featureType": "town",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffffff"
    }
}, {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "poilabel",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "districtlabel",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "road",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "district",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "poilabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "poilabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "poilabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "manmade",
    "elementType": "geometry",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "districtlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffffff"
    }
}, {
    "featureType": "entertainment",
    "elementType": "geometry",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "shopping",
    "elementType": "geometry",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "nationalway",
    "stylers": {
        "level": "6",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "stylers": {
        "level": "7",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "stylers": {
        "level": "8",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "stylers": {
        "level": "9",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "stylers": {
        "level": "10",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "6",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "7",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "8",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "9",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "10",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "6",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "7",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "8",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "9",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "nationalway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "10",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-10"
    }
}, {
    "featureType": "cityhighway",
    "stylers": {
        "level": "6",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "stylers": {
        "level": "7",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "stylers": {
        "level": "8",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "stylers": {
        "level": "9",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "6",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "7",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "8",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "off",
        "level": "9",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "6",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "7",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "8",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "labels",
    "stylers": {
        "visibility": "off",
        "level": "9",
        "curZoomRegionId": "0",
        "curZoomRegion": "6-9"
    }
}, {
    "featureType": "subwaylabel",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "subwaylabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "tertiarywaysign",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "tertiarywaysign",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "provincialwaysign",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "provincialwaysign",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "nationalwaysign",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "nationalwaysign",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "highwaysign",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "highwaysign",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "village",
    "elementType": "labels",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "district",
    "elementType": "labels.text",
    "stylers": {
        "fontsize": "20"
    }
}, {
    "featureType": "district",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "district",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "country",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "country",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "water",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "tertiaryway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "tertiaryway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff10"
    }
}, {
    "featureType": "provincialway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "provincialway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "nationalway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "nationalway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "highway",
    "elementType": "labels.text",
    "stylers": {
        "fontsize": "20"
    }
}, {
    "featureType": "nationalway",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "nationalway",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "nationalway",
    "elementType": "labels.text",
    "stylers": {
        "fontsize": "20"
    }
}, {
    "featureType": "provincialway",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "provincialway",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "provincialway",
    "elementType": "labels.text",
    "stylers": {
        "fontsize": "20"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "labels.text",
    "stylers": {
        "fontsize": "20"
    }
}, {
    "featureType": "cityhighway",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "estate",
    "elementType": "geometry",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "tertiaryway",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "tertiaryway",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "fourlevelway",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "fourlevelway",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "scenicspotsway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "scenicspotsway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "universityway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "universityway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "vacationway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "vacationway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "fourlevelway",
    "elementType": "geometry",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "fourlevelway",
    "elementType": "geometry.fill",
    "stylers": {
        "color": "#12223dff"
    }
}, {
    "featureType": "fourlevelway",
    "elementType": "geometry.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "transportationlabel",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "transportationlabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "transportationlabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "transportationlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "educationlabel",
    "elementType": "labels",
    "stylers": {
        "visibility": "on"
    }
}, {
    "featureType": "educationlabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "educationlabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "educationlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "transportation",
    "elementType": "geometry",
    "stylers": {
        "color": "#113549ff"
    }
}, {
    "featureType": "airportlabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "airportlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "scenicspotslabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "scenicspotslabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "medicallabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "medicallabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "medicallabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "scenicspotslabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "airportlabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "entertainmentlabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "entertainmentlabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "entertainmentlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "estatelabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "estatelabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "estatelabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "businesstowerlabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "businesstowerlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "businesstowerlabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "companylabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "companylabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "companylabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "governmentlabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "governmentlabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "governmentlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "restaurantlabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "restaurantlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "restaurantlabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "hotellabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "hotellabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "hotellabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "shoppinglabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "shoppinglabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "shoppinglabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "lifeservicelabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "lifeservicelabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "lifeservicelabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "carservicelabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "carservicelabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "carservicelabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "financelabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "financelabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "financelabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "otherlabel",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "otherlabel",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "otherlabel",
    "elementType": "labels.icon",
    "stylers": {
        "visibility": "off"
    }
}, {
    "featureType": "manmade",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "manmade",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "transportation",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "transportation",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "education",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "education",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "medical",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "medical",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}, {
    "featureType": "scenicspots",
    "elementType": "labels.text.fill",
    "stylers": {
        "color": "#2dc4bbff"
    }
}, {
    "featureType": "scenicspots",
    "elementType": "labels.text.stroke",
    "stylers": {
        "color": "#ffffff00"
    }
}]
        }
      )
     
      that.center.lng = 121.29
      that.center.lat = 31.14
      that.zoom = 15
      that.newPoint.lng = 116.404
      that.newPoint.lat = 39.915
      that.newPoints=[{
        lng: 121.29,
        lat: 31.14
      },{
        lng: 121.36,
        lat: 31.14
      },{
        lng: 121.30,
        lat: 31.14
      },{
        lng: 121.33,
        lat: 31.16
      },{
        lng: 121.27,
        lat: 31.08
      },{
        lng: 121.469527,
        lat: 31.256568
      },{
        lng: 121.489527,
        lat: 31.286568
      },{
        lng: 121.58,
        lat: 31.15
      },{
        lng: 121.60,
        lat: 31.16
      },{
        lng: 121.62,
        lat: 31.12
      },{
        lng: 121.58,
        lat: 31.13
      },{
        lng: 121.60,
        lat: 31.11
      },{
        lng: 121.62,
        lat: 31.08
      },{
        lng: 121.58,
        lat: 31.01
      },{
        lng: 121.60,
        lat: 30.99
      },{
        lng: 121.62,
        lat: 30.95
      }]
    }
  },
  mounted(){
      
  }
}
</script>
<style lang="stylus" scoped>
.kanban-container
    min-height 100%
    background radial-gradient(circle,#25528C, #0D1838)
    background -moz-radial-gradient(circle,#25528C, #0D1838)
    background -webkit-radial-gradient(circle,#25528C, #0D1838)
    display flex
    flex-direction column
    .btn-view-company
        position absolute
        left 0
        top 0
        width 36px
        height 36px
        margin-left 16px
        margin-top 15px
    .title-line
        font-size 16px
        line-height 21px
        display flex
        justify-content space-between
        padding 0 31px
        margin-bottom 7px
        .name
            color #fff
            width 100px
        .content
            color #03CDFF
            flex 1
            border-bottom solid 1px #03CDFF
    .body-row
        flex 1
        display flex
        .left-colum
            width 421px
            margin-left 39px
            margin-right 18px
        .right-colum
            width 422px
            margin-right 39px
            margin-left 18px
        .main
            flex 1
    .map
        width 100%
        height 610px
    .warn
        li
            list-style none
            padding 0
            margin 0
            width 50%
            display inline-block
            margin-bottom 31px
            img
                width 78px
                height 78px
                float left
            .info
                padding-left 87px
                .title
                    color #fff
                    font-size 14px
                    display inline-block
                    margin-bottom 2px
                    margin-top 8px
                .num
                    color #69D7FE
                    font-size 32px
                    display inline-block
                .hightlight
                    color #FF7A45

</style>
<style lang="stylus">
.kanban-container
    .el-table
        color #fff!important
    .el-table th
        background-color transparent!important
    .el-table td, .el-table th
        padding 9px 0 8px 0
    .el-table th, .el-table tr
        background-color transparent!important
    .el-table, .el-table__expanded-cell
        background-color transparent!important
    .el-table thead
        color #40A9FF!important
    .el-table td, .el-table th.is-leaf
        border 0!important
    .el-table td
        background rgba(0, 80, 179, .2)!important
    .el-table--striped .el-table__body tr.el-table__row--striped td
        background-color transparent!important
    .el-table--border::after, .el-table--group::after, .el-table::before
        background-color transparent!important
</style>